<!--https://swiperjs.com/-->
<!--https://swiperjs.com/vue-->
<template>
  <div class="swiper_ui SwiperCustom SwiperCreator">
    <Swiper
      ref="swiper_first"
      :modules="modules"
      :pagination="swiper_0_pagenation"
      :slides-per-view="1"
      :space-between="50"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <SwiperSlide class="item" v-for="(item, idx) in $_Store.layer_popup_data.FullLayer_0.bj_data.image" :key="item.id" :data-idx="idx">
        <div class="shell">
          <div class="pic">
            <img :src="item" alt=""/>
          </div>
        </div>
      </SwiperSlide>
      <div ref="progressbar" class="swiper-pagination progressbar_pagination"></div>
    </Swiper>
    <Swiper class="fraction_swiper"
            :modules="modules"
            :pagination="swiper_1_pagination"
            @swiper="onSwiper2"
    >
      <SwiperSlide class="item" v-for="(item, idx) in $_Store.layer_popup_data.FullLayer_0.bj_data.image" :key="item.id" :data-idx="idx">
      </SwiperSlide>
      <div ref="fraction" class="swiper-pagination fraction_pagination"></div>
    </Swiper>
    <div ref="pagination_area" class="pagination_area"></div>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Pagination, Controller} from "swiper";

import 'swiper/css';
import "swiper/css/pagination";

export default {
  components : {
    Swiper,
    SwiperSlide,
  },
  name : "SwiperCreator",
  data() {
    return {
      message : "Hello Vue",
      modules : [Pagination, Controller],
      swiper_0 : null,
      swiper_0_pagenation : {
        el : '.progressbar_pagination',
        type : 'progressbar',
        clickable : true,
      },
      swiper_1 : null,
      swiper_1_pagination : {
        el : '.fraction_pagination',
        type : 'fraction',
        formatFractionCurrent : function (number) {
          return ('0' + number).slice(-2);
        },
        formatFractionTotal : function (number) {
          return ('0' + number).slice(-2);
        },
        renderFraction : function (currentClass, totalClass) {
          return `<span class='${currentClass}'></span> <span>/</span> <span class='${totalClass}'></span>`;
        },
      }
    };
  },
  methods : {
    
    // eslint-disable-next-line no-unused-vars
    onSwiper(swiper) {
      // console.log('onSwiper');
      // console.log(swiper);
      this.swiper_0 = swiper;
      this.$emit('swiper_init', swiper);
    },
    onSwiper2(swiper) {
      // console.log('onSwiper2');
      // console.log(swiper);
      this.swiper_1 = swiper;
      this.swiper_0.controller.control = this.swiper_1;
      
      // 서로 다른 스와이퍼에 있는 페이지 네이션을 바깥으로 빼내서 합친다.
      const pagination_area = this.$refs.pagination_area;
      const fraction = this.$refs.fraction;
      const progressbar = this.$refs.progressbar;
      pagination_area.append(fraction);
      pagination_area.append(progressbar);
    },
    onSlideChange() {
      // console.log('slide change');
    }
  },
}
</script>
