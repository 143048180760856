<template>
  <footer>© 2022. K ENTERTAINMENT. All rights reserved.</footer>
</template>
<script>
export default {
  name: "FooterBasic",
  data() {
    return {
      message: "Hello Vue",
    };
  },
};
</script>
