import { createRouter } from "vue-router";
import { createWebHashHistory } from "vue-router";
import { createWebHistory } from "vue-router";
import { useCommonStore } from "@/stores/common";

import root from "@/routes/root";
import login from "@/routes/login";
import _guide from "@/routes/_guide";
import about from "@/routes/about";
import creator from "@/routes/creator";
import qna from "@/routes/qna";
import news from "@/routes/news";
// import career from "@/routes/career";
import Util from "@/utils/Util";

// 개발환경 구분
// console.log("process.env : ", process.env);

const routes = [
  ...root,
  ...login,
  ..._guide,
  ...about,
  ...creator,
  ...qna,
  ...news,
];

const insertDefaultLayout = (array) => {
  array.map(function (obj) {
    if (obj.name) {
      if (obj.meta) {
        if (obj.meta.layout) {
          //
        } else {
          // meta O, layout X
          obj.meta.layout = "LayoutDefault";
        }
      } else {
        // meta X, layout X
        obj.meta = {
          layout: "LayoutDefault",
        };
      }
    }
  });
};
insertDefaultLayout(routes);

/**
 * history : https://v3.router.vuejs.org/kr/guide/essentials/history-mode.html
 * scrollBehavior : https://router.vuejs.org/guide/advanced/scroll-behavior.html / 브라우저의 뒤로/앞으로 버튼에 의해서만 작동
 * @type {Router}
 */
const router = createRouter({
  history:
    process.env.NODE_ENV === "localhost"
      ? createWebHashHistory()
      : createWebHistory(),
  // history : createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {
  // console.log('beforeEach');
  // console.log(to, from);

  // #page data-name 에 넣어주기 위해 담아둠
  const store = useCommonStore();
  store.page_name = to.name;

  // 레이어 팝업 전부 닫기
  const layer_popup_data = store.layer_popup_data;
  for (const key in layer_popup_data) {
    const data = layer_popup_data[key];
    data.visible = false;
  }

  // 이벤트 핸들러 청소
  let handler_list = window.HANDLER_LIST;
  handler_list.forEach((handler, i) => {
    window.removeEventListener("click", handler);
    handler_list.splice(i, 1);
  });
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // console.log('afterEach');
  // console.log(to, from);
  // 페이지 이동시
  // 메뉴 닫히게 추가
  const store = useCommonStore();
  store.active_aside = false;
  const body = document.body;
  Util.removeClass(body, "deactive_scroll");
  /*
  if (to.name == "채용") {
    store.is_career_btn_hide = true;
  } else {
    store.is_career_btn_hide = false;
  }
  */
});

export default router;
